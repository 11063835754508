


function Geolift() {

    return (
      <>
      <div>
  
        Geolift Component
      
      </div>
      </>
    );
  }
  
  export default Geolift;