import trueliftlogo from "../assets/logo/tl_logo_big.png"


function About() {

    return (
        <div style={{width:"100%", height:"650px", display:"flex", justifyContent:"center", alignItems:"center"}}>

{/* transform:"rotate(46.25deg)", */}


            {/* <div style={{width:"200px", height:"200px", display:"flex", justifyContent:"center", alignItems:"center", gap:"3rem",  backgroundColor:"darkblue"}}>


                    <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.1rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"11.25px solid transparent", borderRight:"11.25px solid transparent", borderBottom:"17.5px solid #4db2e0"}}></div>

                            <div style={{width:"5.5px", height:"55px", backgroundColor:"rgb(31,31,31)"}}></div>
                        </div>

                    </div>



                    <div style={{display:"flex", flexDirection:"column", gap:"3rem", backgroundColor:"yellow"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.1rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"11.25px solid transparent", borderRight:"11.25px solid transparent", borderBottom:"17.5px solid #4db2e0"}}></div>

                            <div style={{width:"5.5px", height:"55px", backgroundColor:"rgb(31,31,31)"}}></div>
                        </div>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.1rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"11.25px solid transparent", borderRight:"11.25px solid transparent", borderBottom:"17.5px solid #4db2e0"}}></div>

                            <div style={{width:"5.5px", height:"55px", backgroundColor:"rgb(31,31,31)"}}></div>
                        </div>

                    </div>



                    <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.1rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"11.25px solid transparent", borderRight:"11.25px solid transparent", borderBottom:"17.5px solid #4db2e0"}}></div>

                            <div style={{width:"5.5px", height:"55px", backgroundColor:"rgb(31,31,31)"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.1rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"11.25px solid transparent", borderRight:"11.25px solid transparent", borderBottom:"17.5px solid #4db2e0"}}></div>

                            <div style={{width:"5.5px", height:"55px", backgroundColor:"rgb(31,31,31)"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.1rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"11.25px solid transparent", borderRight:"11.25px solid transparent", borderBottom:"17.5px solid #4db2e0"}}></div>

                            <div style={{width:"5.5px", height:"55px", backgroundColor:"rgb(31,31,31)"}}></div>
                        </div>

                    </div>



            </div> */}




            <img  src = {trueliftlogo} alt="truelift-logo" width={1200}></img>


        </div>
    );
  }
  
  export default About;
