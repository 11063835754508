


function PrivacyPolicy() {          
    

    return (
        <div   style={{display:"flex", justifyContent:"center"}}> 
            <div  className="PPforLaptops"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                
                <p  style={{fontSize:"36px"}}>Privacy Policy</p>

                <p style={{fontSize:"16px"}}>Truelift is committed to protecting your privacy. This Statement of Privacy applies to the<br/> Truelift website and governs data collection and usage. By using the Truelift website, you<br/> consent to the data practices described in this statement.</p>

<div  style={{width:"auto", display:"flex", flexDirection:"column", alignItems:"self-start", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bolder", fontSize:"22px"}}>Collection of your Personal Information</p>
                    <p>Truelift collects personally identifiable information, such as your e-mail address, name,<br/> address and/or telephone number. Truelift also collects anonymous demographic information,<br/> which is not unique to you, such as your ZIP code, age, gender, preferences, interests and<br/> favourites.</p>
                    <p>There is also information about your computer hardware and software that is automatically<br/> collected by Truelift. This information can include: your IP address, browser type, domain<br/> names, access times and referring website addresses. This information is used by Truelift for<br/> the operation of the service, to maintain quality of the service, and to provide general<br/> statistics regarding use of the Truelift website.</p>
                    <p>In the event that you contact us for customer support through the website, we may also<br/> request that you provide additional User Information relevant to the support requested by<br/> you. Users are under no obligation to provide such information. However, we cannot<br/> guarantee that we can provide customer support in the event that relevant information<br/> related to the use of our products or services is withheld. We may retain communications<br/> relating to customer support, and/or information drawn from such communications, for<br/> statistical and/or reference purposes.</p>
                    <p>Please keep in mind that if you directly disclose personally identifiable information or<br/> personally sensitive data through Truelift public message boards or forums, this information<br/> may be collected and used by others. Note: Truelift does not read your private online<br/> communications with others.</p>
                    <p>Truelift encourages you to review the privacy statements of websites you choose to link to<br/> from Truelift so that you can understand how those websites collect, use and share your<br/> information. Truelift is not responsible for the privacy statements or other content on websites<br/> outside of the Truelift and Truelift family of websites or the use of your information by such<br/> outside websites.</p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Use of your Personal Information</p>
                    <p>Truelift collects and uses your personal information to operate the Truelift website and deliver<br/> the services you have requested. Truelift may also use your personally identifiable information<br/> to inform you of other products or services available from Truelift and its affiliates. Truelift<br/> may also contact you via surveys to conduct research about your opinion of current services<br/> or of potential new services.</p>
                    <p>Truelift does not sell, rent or lease its customer lists to third parties. Truelift may, from time to<br/> time, contact you on behalf of external business partners about a particular offering that may<br/> be of interest to you. In those cases, your unique personally identifiable information (e-mail,<br/> name, address, telephone number) is not transferred to the third party. In addition, Truelift<br/> may share data with trusted partners to help us perform statistical analysis, send you email<br/> or postal mail, provide customer support, or arrange for deliveries. Such third parties are<br/> prohibited from using your personal information except to provide these services to Truelift,<br/> and they are required to maintain the confidentiality of your information.</p>
                    <p>Truelift does not use or disclose sensitive personal information, such as race, religion, or<br/> political affiliations, without your explicit consent.</p>
                    <p>Truelift keeps track of the websites and pages you visit within Truelift, in order to determine<br/> what Truelift services are the most popular. This data is used to deliver customized content and<br/> advertising within Truelift to customers whose behavior indicates that they are interested in a<br/> particular subject area.</p>
                    <p>Truelift websites will disclose your personal information, without notice, only if required to do so<br/> by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of<br/> the law or comply with legal process served on Truelift or the site; (b) protect and defend the<br/> rights or property of Truelift (including enforcing this agreement); and, (c) act under exigent<br/> circumstances to protect the personal safety of users of Truelift, or the public.</p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Use of Cookies</p>
                    <p>The Truelift website use "cookies" to help you personalize your online experience. A cookie is a<br/> text file that is placed on your hard disk by a Web page server. Cookies are not used to run<br/> programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can<br/> only be read by a web server in the domain that issued the cookie to you.</p>
                    <p>One of the primary purposes of cookies is to provide a convenience feature to save you time.<br/> The purpose of a cookie is to tell the Web server that you have returned to a specific page. For<br/> example, if you personalize Truelift pages, or register with Truelift site or services, a cookie helps<br/> Truelift to recall your specific information on subsequent visits. This simplifies the process of<br/> recording your personal information, such as billing addresses, shipping addresses, and so on.<br/> When you return to the same Truelift website, the information you previously provided can be<br/> retrieved, so you can easily use the Truelift features that you customized.</p>
                    <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept<br/> cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you<br/> choose to decline cookies, you may not be able to fully experience the interactive features of<br/> the Truelift services or websites you visit.</p>   
                    <p>If you provide Truelift with user information, you have the following rights with respect to that<br/> information:</p>
                    
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px", padding:"1rem 0"}}>
                        <li>To review the user information that you have supplied to us</li>
                        <li>To request that we correct any errors, outdated information, or omissions in user information that<br/> you have supplied to us</li>
                        <li>To request that your user information not be used to contact you</li>
                        <li>To request that your user information be removed from any solicitation list that we use</li>
                        <li>To request that your user information be deleted from our records and</li>
                        <li>To opt out of being solicited by this website or third parties</li>
                    </ul>

                    <p>If you request that your user information be deleted from our records, there may be a brief<br/> delay in our processing that request, while verify that the request is valid and originates from<br/> you as opposed to an unauthorized third party. If you request that your information be deleted,<br/> we reserve the right to terminate and/or limit your access to the Truelift website.</p>
                    <p>To exercise any of these rights, please contact us at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a></p>

                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Security of your Personal Information</p>
                    <p>No method of safeguarding information is 100% secure. Truelift uses a variety of security<br/> technologies and procedures to help protect your personal information from unauthorized<br/> access, use or disclosure. Truelift secures the personally identifiable information you provide on<br/> computer servers in a controlled, secure environment, protected from unauthorized access, use<br/> or disclosure. When personal information (such as a credit card number) is transmitted to other<br/> websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL)<br/> protocol.</p>
                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Google Ads and Google Analytics</p>
                    <p>Truelift uses the Google Ads API Service in order to generate Reports and Dashboards for Google<br/> Ads and Google Analytics. Because of that by using Truelift you are agreeing to be bound by the<br/> <a href="https://static.googleusercontent.com/media/www.google.com/en//ads/terms.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Ads Terms of Service</a>, the <a href="https://marketingplatform.google.com/about/analytics/terms/us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Analytics Terms of Service</a> and by the <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Privacy<br/> Policy</a>. Truelift will collect Google Ads and Google Analytics based on the metrics you add to your<br/> reports and dashboards on the respective intefaces. This data belongs to you and will not be shared<br/> with any third-party. If you want to revoke your Google Accounts from Truelift you should access<br/> your <a href="https://myaccount.google.com/connections?filters=3,4&hl=en-GB" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Security Settings</a>. If you have any questions or complaints you should contact us via<br/> email at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a></p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Changes to this Statement</p>
                    <p>Truelift will occasionally update this Statement of Privacy to reflect company and customer<br/> feedback. Truelift encourages you to periodically review this Statement to be informed of how<br/> Truelift is protecting your information. When such a change is made, we will update the Last<br/> Updated date above.</p>
                </div>   

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Enforcement of this Privacy Statement</p>
                    <p>If you have questions regarding this statement, you should contact us at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a>.</p>
                </div>       

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>To Unsubscribe From Truelift</p>
                    <p>You may unsubscribe from Truelift marketing communications by clicking on the “unsubscribe”<br/> link located on the bottom of the company’s emails. </p>
                </div>      

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Contact Information</p>
                    <p>Truelift welcomes your comments regarding this Statement of Privacy. If you believe that Truelift<br/> has not adhered to this Statement, please contact Truelift at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a>. We will use<br/> commercially reasonable efforts to promptly determine and remedy the problem. Truelift is<br/> located at 4-137/27 Korantadi Marakada Mangalore 575015..  </p>
                </div>   
</div> 

            </div>


            <div  className="PPforMobiles"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                
                <p  style={{width:"100%", fontSize:"32px", textAlign:"center"}}>Privacy Policy</p>

                <p style={{fontSize:"15px"}}>Truelift is committed to protecting your privacy. This Statement of Privacy applies to the Truelift website and governs data collection and usage. By using the Truelift website, you consent to the data practices described in this statement.</p>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bolder", fontSize:"22px"}}>Collection of your Personal Information</p>
                    <p>Truelift collects personally identifiable information, such as your e-mail address, name, address and/or telephone number. Truelift also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favourites.</p>
                    <p>There is also information about your computer hardware and software that is automatically collected by Truelift. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used by Truelift for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Truelift website.</p>
                    <p>In the event that you contact us for customer support through the website, we may also request that you provide additional User Information relevant to the support requested by you. Users are under no obligation to provide such information. However, we cannot guarantee that we can provide customer support in the event that relevant information related to the use of our products or services is withheld. We may retain communications relating to customer support, and/or information drawn from such communications, for statistical and/or reference purposes.</p>
                    <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Truelift public message boards or forums, this information may be collected and used by others. Note: Truelift does not read your private online communications with others.</p>
                    <p>Truelift encourages you to review the privacy statements of websites you choose to link to from Truelift so that you can understand how those websites collect, use and share your information. Truelift is not responsible for the privacy statements or other content on websites outside of the Truelift and Truelift family of websites or the use of your information by such outside websites.</p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Use of your Personal Information</p>
                    <p>Truelift collects and uses your personal information to operate the Truelift website and deliver the services you have requested. Truelift may also use your personally identifiable information to inform you of other products or services available from Truelift and its affiliates. Truelift may also contact you via surveys to conduct research about your opinion of current services or of potential new services.</p>
                    <p>Truelift does not sell, rent or lease its customer lists to third parties. Truelift may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, Truelift may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. Such third parties are prohibited from using your personal information except to provide these services to Truelift, and they are required to maintain the confidentiality of your information.</p>
                    <p>Truelift does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.</p>
                    <p>Truelift keeps track of the websites and pages you visit within Truelift, in order to determine what Truelift services are the most popular. This data is used to deliver customized content and advertising within Truelift to customers whose behavior indicates that they are interested in a particular subject area.</p>
                    <p>Truelift websites will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Truelift or the site; (b) protect and defend the rights or property of Truelift (including enforcing this agreement); and, (c) act under exigent circumstances to protect the personal safety of users of Truelift, or the public.</p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Use of Cookies</p>
                    <p>The Truelift website use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies are not used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
                    <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Truelift pages, or register with Truelift site or services, a cookie helps Truelift to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Truelift website, the information you previously provided can be retrieved, so you can easily use the Truelift features that you customized.</p>
                    <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Truelift services or websites you visit.</p>   
                    <p>If you provide Truelift with user information, you have the following rights with respect to that information:</p>
                    
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px", padding:"1rem 0"}}>
                        <li>To review the user information that you have supplied to us</li>
                        <li>To request that we correct any errors, outdated information, or omissions in user information that you have supplied to us</li>
                        <li>To request that your user information not be used to contact you</li>
                        <li>To request that your user information be removed from any solicitation list that we use</li>
                        <li>To request that your user information be deleted from our records and</li>
                        <li>To opt out of being solicited by this website or third parties</li>
                    </ul>

                    <p>If you request that your user information be deleted from our records, there may be a brief delay in our processing that request, while verify that the request is valid and originates from you as opposed to an unauthorized third party. If you request that your information be deleted, we reserve the right to terminate and/or limit your access to the Truelift website.</p>
                    <p>To exercise any of these rights, please contact us at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a></p>

                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Security of your Personal Information</p>
                    <p>No method of safeguarding information is 100% secure. Truelift uses a variety of security technologies and procedures to help protect your personal information from unauthorized access, use or disclosure. Truelift secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Google Ads and Google Analytics</p>
                    <p>Truelift uses the Google Ads API Service in order to generate Reports and Dashboards for Google Ads and Google Analytics. Because of that by using Truelift you are agreeing to be bound by the <a href="https://static.googleusercontent.com/media/www.google.com/en//ads/terms.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Ads Terms of Service</a>, the <a href="https://marketingplatform.google.com/about/analytics/terms/us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Analytics Terms of Service</a> and by the <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Privacy Policy</a>. Truelift will collect Google Ads and Google Analytics based on the metrics you add to your reports and dashboards on the respective intefaces. This data belongs to you and will not be shared with any third-party. If you want to revoke your Google Accounts from Truelift you should access your <a href="https://myaccount.google.com/connections?filters=3,4&hl=en-GB" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>Google Security Settings</a>. If you have any questions or complaints you should contact us via email at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a></p>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Changes to this Statement</p>
                    <p>Truelift will occasionally update this Statement of Privacy to reflect company and customer feedback. Truelift encourages you to periodically review this Statement to be informed of how Truelift is protecting your information. When such a change is made, we will update the "Last Updated" date above.</p>
                </div>   

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Enforcement of this Privacy Statement</p>
                    <p>If you have questions regarding this statement, you should contact us at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a>.</p>
                </div>       

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>To Unsubscribe From Truelift</p>
                    <p>You may unsubscribe from Truelift marketing communications by clicking on the “unsubscribe” link located on the bottom of the company’s emails. </p>
                </div>      

                <div style={{display:"flex", flexDirection:"column", gap:"1rem", fontSize:"15px"}}>
                    <p  style={{fontWeight:"bold", fontSize:"22px"}}>Contact Information</p>
                    <p>Truelift welcomes your comments regarding this Statement of Privacy. If you believe that Truelift has not adhered to this Statement, please contact Truelift at <a href="mailto:support@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>support@truelift.ai</a>. We will use commercially reasonable efforts to promptly determine and remedy the problem. Truelift is located at 4-137/27 Korantadi Marakada Mangalore 575015..  </p>
                </div>    

            </div>
        </div>
    )
}
  
export default PrivacyPolicy;