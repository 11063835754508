import { FcCheckmark } from "react-icons/fc";
import logo from "../assets/logo/truelift_logo_transparent.png";
import "../App.css";
import useWindowDimensions from '../useWindowDimensions';          // Using it for responsiveness in terms of height
import { useInView } from 'react-intersection-observer'; // Install with npm install react-intersection-observer



function Section3({sec3Ref}) {            
    

    const { width, height } = useWindowDimensions();

    const { ref: ref_3, inView } = useInView({ threshold: 0.25 });   // It means that if Section1's 25% portion comes in view, then make "inView = true"
    //console.log(" inView Section 1 :- ", inView);

    

    return (
        <div   ref={sec3Ref}       style={{fontSize:"42px", display:"flex", justifyContent:"center"}}> 
            <div  className="sec3-parent"   ref={ref_3}     style={{backgroundColor:"white", width:"1536px", height:height-88, maxHeight:"642px",  padding:"3rem 4rem", position:"relative"}}>



                <div  className="sec1-arrows">

                    <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>

                <div  className="sec1-arrows2">

                    <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>

                <div  className="sec1-arrows3">

                    <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>


 

                <div  className="sec3-left">
                    <div  className="sec3-left-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                        
                        <p>  Why should you  </p>
                        <div  style={{display:"flex", alignItems:"center"}}>    
                            <p>  trust  </p>   
                            <div  className="tl-logo-sec3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                            <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>  ?  </p>   
                        </div>

                    </div>
                </div>


                <div  className="sec3-right">
                    <div    className="sec3-right-cont">
                        
                        <div style={{display:"flex"}}    className={` ${inView ? 'para1-animation' : ''}  `}>
                            {/* <FcCheckmark fontSize="28px"/> */}

                            <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                <div  className="tl-logo-sec3-i">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                                <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                                <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                                <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                                <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                    <p  style={{paddingLeft:"0.4rem"}}>is built by experts with extensive </p>
                                </div>
                                <p  style={{marginLeft:"6px"}}> experience in Marketing Science teams </p>
                                <p  style={{marginLeft:"6px"}}> at Meta, Google and Amazon </p>
                            </div>

                        </div>

                        <div style={{display:"flex"}}    className={` ${inView ? 'para2-animation' : ''}  `}>
                            {/* <FcCheckmark fontSize="28px"/> */}

                            <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex"}}>
                                    {/* <img  src={logo}  alt="truelift_logo"   className="logo-width4"  style={{objectFit:"contain"}}/> */}
                                    <p  style={{paddingTop:"0.3rem", marginLeft:"6px"}}>Our core team of Phd Economists have </p>
                                </div>
                                <p  style={{marginLeft:"6px"}}> more than 25 years of real world media </p>
                                <p  style={{marginLeft:"6px"}}> measurement experience </p>
                            </div>

                        </div>

                        <div style={{display:"flex"}}    className={` ${inView ? 'para3-animation' : ''}  `}>
                            {/* <FcCheckmark fontSize="28px"/> */}

                            <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex"}}>
                                    {/* <img  src={logo}  alt="truelift_logo"    className="logo-width4"  style={{objectFit:"contain"}}/> */}
                                    <p  style={{paddingTop:"0.3rem", marginLeft:"6px"}}>Our products are not black boxes. They </p>
                                </div>                                                        
                                                                 
                                <p  style={{marginLeft:"6px"}}> are built on open source tools, and  </p>
                                <p  style={{marginLeft:"6px"}}> transparency is a key cornerstone of  </p>
                                <p  style={{marginLeft:"6px"}}> our product philosophy.  </p>
                                
                            </div>

                        </div>
                    
                    </div>
                </div>


            </div>




            <div  className="sec3-parent2"   ref={ref_3}     style={{backgroundColor:"white", width:"1536px", height:height-78, maxHeight:"642px",  padding:"3rem 4rem", position:"relative"}}>



                <div  className="sec1-arrows">

                    <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>

                <div  className="sec1-arrows2">

                    <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                            <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>

                <div  className="sec1-arrows3">

                    <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                            <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>




                <div  className="sec3-left">
                    <div  className="sec3-left-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                        
                        <p>  Why should you  </p>
                        <div  style={{display:"flex", alignItems:"center"}}>    
                            <p>  trust  </p>   
                            <div  className="tl-logo-sec3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                            <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>  ?  </p>   
                        </div>

                    </div>
                </div>


                <div  className="sec3-right">
                    <div    className="sec3-right-cont">
                        
                        <div style={{display:"flex"}}    className={` ${inView ? 'para1-animation' : ''}  `}>
                            {/* <FcCheckmark fontSize="28px"/> */}

                            <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <div  className="tl-logo-sec3-i">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                            <g id="Layer_2" data-name="Layer 2">
                                                <g id="Layer_1-2" data-name="Layer 1">
                                                    <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                                    <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                                    <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                                    <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                                    <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <p  style={{paddingLeft:"0.4rem"}}>is built by experts with extensive </p>
                                </div>
                                <p  style={{marginLeft:"6px"}}> experience in Marketing Science teams </p>
                                <p  style={{marginLeft:"6px"}}> at Meta, Google and Amazon </p>
                            </div>

                        </div>

                        <div style={{display:"flex"}}    className={` ${inView ? 'para2-animation' : ''}  `}>
                            {/* <FcCheckmark fontSize="28px"/> */}

                            <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex"}}>
                                    {/* <img  src={logo}  alt="truelift_logo"   className="logo-width4"  style={{objectFit:"contain"}}/> */}
                                    <p  style={{paddingTop:"0.3rem", marginLeft:"6px"}}>Our core team of Phd Economists have </p>
                                </div>
                                <p  style={{marginLeft:"6px"}}> more than 25 years of real world media </p>
                                <p  style={{marginLeft:"6px"}}> measurement experience </p>
                            </div>

                        </div>

                        <div style={{display:"flex"}}    className={` ${inView ? 'para3-animation' : ''}  `}>
                            {/* <FcCheckmark fontSize="28px"/> */}

                            <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex"}}>
                                    {/* <img  src={logo}  alt="truelift_logo"    className="logo-width4"  style={{objectFit:"contain"}}/> */}
                                    <p  style={{paddingTop:"0.3rem", marginLeft:"6px"}}>Our products are not black boxes. They  </p>
                                </div>
                                <p  style={{marginLeft:"6px"}}> are built on open source tools, and  </p>
                                <p  style={{marginLeft:"6px"}}> transparency is a key cornerstone of  </p>
                                <p  style={{marginLeft:"6px"}}> our product philosophy.  </p>
                            </div>                         
                            
                        </div>
                    
                    </div>
                </div>


            </div>




            <div  className="sec3-parent3"   ref={ref_3}     style={{backgroundColor:"white", width:"1536px", height:height-68, maxHeight:"642px",  padding:"3rem 4rem", position:"relative"}}>



            <div  className="sec1-arrows">

                <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>

                </div>

            </div>

            <div  className="sec1-arrows2">

                <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.6rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"9px solid transparent", borderRight:"9px solid transparent", borderBottom:"12.6px solid #4db2e0"}}></div>

                        <div style={{width:"3.8px", height:"39.6px", backgroundColor:"black"}}></div>
                    </div>

                </div>

            </div>

            <div  className="sec1-arrows3">

                <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                        <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                        <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                        <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"column", gap:"1.1rem"}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                        <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                        <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.4rem"}}>
                        <div style={{width:"0", height:"0", borderLeft:"7.8px solid transparent", borderRight:"7.8px solid transparent", borderBottom:"11px solid #4db2e0"}}></div>

                        <div style={{width:"3.3px", height:"33px", backgroundColor:"black"}}></div>
                    </div>

                </div>

            </div>




            <div  className="sec3-left">
                <div  className="sec3-left-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                    
                    <p>  Why should you  </p>
                    <div  style={{display:"flex", alignItems:"center"}}>    
                        <p>  trust  </p>   
                        <div  className="tl-logo-sec3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                        <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                        <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                        <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                        <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                        <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                        <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                        <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                        <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p>  ?  </p>   
                    </div>

                </div>
            </div>


            <div  className="sec3-right">
                <div    className="sec3-right-cont">
                    
                    <div style={{display:"flex"}}    className={` ${inView ? 'para1-animation' : ''}  `}>
                        {/* <FcCheckmark fontSize="28px"/> */}

                        <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <div  className="tl-logo-sec3-i">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                                <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                                <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                                <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                                <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                                <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <p  style={{paddingLeft:"0.4rem"}}>is built by experts with extensive </p>
                            </div>
                            <p  style={{marginLeft:"6px"}}> experience in Marketing Science teams </p>
                            <p  style={{marginLeft:"6px"}}> at Meta, Google and Amazon </p>
                        </div>

                    </div>

                    <div style={{display:"flex"}}    className={` ${inView ? 'para2-animation' : ''}  `}>
                        {/* <FcCheckmark fontSize="28px"/> */}

                        <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                            <div style={{display:"flex"}}>
                                {/* <img  src={logo}  alt="truelift_logo"   className="logo-width4"  style={{objectFit:"contain"}}/> */}
                                <p  style={{paddingTop:"0.3rem", marginLeft:"6px"}}>Our core team of Phd Economists have </p>
                            </div>
                            <p  style={{marginLeft:"6px"}}> more than 25 years of real world media </p>
                            <p  style={{marginLeft:"6px"}}> measurement experience </p>
                        </div>

                    </div>

                    <div style={{display:"flex"}}    className={` ${inView ? 'para3-animation' : ''}  `}>
                        {/* <FcCheckmark fontSize="28px"/> */}

                        <div  className="sec3-right-container"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                            <div style={{display:"flex"}}>
                                {/* <img  src={logo}  alt="truelift_logo"    className="logo-width4"  style={{objectFit:"contain"}}/> */}
                                <p  style={{paddingTop:"0.3rem", marginLeft:"6px"}}>Our products are not black boxes. They  </p>
                            </div>
                                <p  style={{marginLeft:"6px"}}> are built on open source tools, and  </p>
                                <p  style={{marginLeft:"6px"}}> transparency is a key cornerstone of  </p>
                                <p  style={{marginLeft:"6px"}}> our product philosophy.  </p>
                        </div>

                    </div>
                
                </div>
            </div>


            </div>





            <div  className="sec3-parent4"   ref={ref_3}  style={{backgroundColor:"white", width:"1536px", height:height-65, padding:"1rem", position:"relative", flexDirection:"column"}}>




                <div  style={{position:"absolute", width:"50px", height:"50px", display:"flex", justifyContent:"center", alignItems:"center", gap:"1rem", transform: "rotate(46.25deg)"}}>

                    <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.3rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"5.985px solid transparent", borderRight:"5.985px solid transparent", borderBottom:"8.379px solid #4db2e0"}}></div>

                            <div style={{width:"2.85px", height:"26.334px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.3rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"5.985px solid transparent", borderRight:"5.985px solid transparent", borderBottom:"8.379px solid #4db2e0"}}></div>

                            <div style={{width:"2.85px", height:"26.334px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.3rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"5.985px solid transparent", borderRight:"5.985px solid transparent", borderBottom:"8.379px solid #4db2e0"}}></div>

                            <div style={{width:"2.85px", height:"26.334px", backgroundColor:"black"}}></div>
                        </div>

                    </div>


                    <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.3rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"5.985px solid transparent", borderRight:"5.985px solid transparent", borderBottom:"8.379px solid #4db2e0"}}></div>

                            <div style={{width:"2.85px", height:"26.334px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.3rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"5.985px solid transparent", borderRight:"5.985px solid transparent", borderBottom:"8.379px solid #4db2e0"}}></div>

                            <div style={{width:"2.85px", height:"26.334px", backgroundColor:"black"}}></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"0.3rem"}}>
                            <div style={{width:"0", height:"0", borderLeft:"5.985px solid transparent", borderRight:"5.985px solid transparent", borderBottom:"8.379px solid #4db2e0"}}></div>

                            <div style={{width:"2.85px", height:"26.334px", backgroundColor:"black"}}></div>
                        </div>

                    </div>

                </div>







                <div  style={{width:"100%", height:"35%", display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"5rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", fontSize:"23px", color:"black", lineHeight:"1.5", fontFamily:"Toboggan", fontWeight:"800"}}>

                        <p>  Why should you  </p>
                        <div  style={{display:"flex", alignItems:"center"}}>    
                            <p>  trust  </p>   
                            <div  className="tl-logo-m3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                            <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                            <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                            <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>  ?  </p>   
                        </div>

                    </div>
                </div>




                <div  style={{width:"100%", height:"65%", display:"flex", justifyContent:"center", alignItems:"flex-start", paddingTop:"1rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", overflow:"hidden", gap:"2rem"}}>  

                        <div style={{display:"flex"}}  className={` ${inView ? 'para1-animation' : ''}  `}>
                        
                            <div  className="sec3-px"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <div  className="tl-logo-m4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                                            <g id="Layer_2" data-name="Layer 2">
                                                <g id="Layer_1-2" data-name="Layer 1">
                                                    <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                                    <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                                    <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                                    <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                                    <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                                                    <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <p  style={{paddingLeft:"0.4rem"}}>is built by experts with extensive </p>
                                </div>
                                <p > experience in Marketing Science teams </p>
                                <p > at Meta, Google and Amazon </p>
                            </div>

                        </div>

                        <div style={{display:"flex"}}  className={` ${inView ? 'para2-animation' : ''}  `}>

                            <div  className="sec3-px"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <p > Our core team of Phd Economists have </p>
                                <p > more than 25 years of real world media </p>
                                <p > measurement experience </p>
                            </div>

                        </div> 

                        <div style={{display:"flex"}}  className={` ${inView ? 'para3-animation' : ''}  `}>

                            <div  className="sec3-px"  style={{fontFamily:"Toboggan", fontWeight:"800"}}>
                                <p >Our products are not black boxes. They  </p>
                                <p > are built on open source tools, and  </p>
                                <p > transparency is a key cornerstone of  </p>
                                <p > our product philosophy.  </p>
                            </div>

                        </div>  
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Section3;