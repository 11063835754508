

import useWindowDimensions from '../useWindowDimensions';          // Using it for responsiveness in terms of height
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';


function ContactUs() {

    const { width, height } = useWindowDimensions();
    let Xheight = 88.01;
    if(width >= 1200  &&  width <= 1300) Xheight=78.01
    else if(width >= 900  &&  width <= 1100) Xheight=68.01
    else if(width >= 335  &&  width <= 900) Xheight=65.01


    return (
        <div style={{width:"100%", height:height-Xheight, backgroundColor:"rgb(25,38,77)", display:"flex", justifyContent:"center", alignItems:"center"}}>





        <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
            <Link to="/"> 
                <div    style={{color:"white", display:"flex", alignItems:"center", fontSize:"17px", gap:"0.3rem", textDecoration: "underline"}}>
                    <IoArrowBack />
                    <p>back to home</p>
                </div>
            </Link>
            


            <div  style={{color:"white"}}>
                <p  style={{fontSize:"30px"}}>Pratham Hegde,</p>
                <p  style={{fontSize:"22px"}}>Cofounder, Truelift</p>
                <p  style={{fontSize:"22px"}}>Mob: +91 9845446790</p>
                <p> </p>
                <p  style={{fontSize:"22px"}}>Email : <a href="mailto:pratham@truelift.ai" style={{ textDecoration: "underline", textDecorationThickness: "1px", color:"rgb(71, 129, 243)" }}>pratham@truelift.ai</a></p>
            </div>
        </div>

        </div>
    );
  }
  
  export default ContactUs;
