import useWindowDimensions from '../useWindowDimensions';          // Using it for responsiveness in terms of height


function Resources() {

    const { width, height } = useWindowDimensions();

    return (
        <div  style={{width:"100%", height:height-89, display:"flex", flexDirection:"column"}}>
            


            <div  style={{width:"100%", height:"50%", display:"flex"}}>
                {/* <div  style={{width:"50%", height:"100%", scale:"50%"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                        <defs>
                        <style>
                            {`.cls-1{fill:#1f1f1f;}.cls-2{fill:#4db2e0;}`}
                        </style>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <path className="cls-1" d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                            <path className="cls-1" d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                            <path className="cls-1" d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                            <path className="cls-1" d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                            <path className="cls-1" d="M516,150.63V0.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                            <path className="cls-1" d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                            <path className="cls-1" d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                            <path className="cls-1" d="M585.17,47.8h8.46v128h-8.46Z"/>
                            <polyline className="cls-2" points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                        </g>
                        </g>
                    </svg>
                </div>

                <div  style={{width:"50%", height:"100%", backgroundColor:"black"}}>
                    <div  style={{width:"100%", height:"100%", scale:"50%"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                            <defs>
                            <style>
                                {`.cls-1{fill:#4db2e0;}.cls-2{fill:#fff;}`}
                            </style>
                            </defs>
                            <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path className="cls-1" d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                                <path className="cls-1" d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                                <path className="cls-1" d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                                <path className="cls-1" d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                                <path className="cls-2" d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                                <path className="cls-2" d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                                <path className="cls-2" d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                                <path className="cls-2" d="M585.17,47.8h8.46v128h-8.46Z"/>
                                <polyline className="cls-1" points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                            </g>
                            </g>
                        </svg>
                    </div>
                </div> */}
            </div>



            <div  style={{width:"100%", height:"50%", display:"flex"}}>
            </div>



        </div>
    );
  }
  
  export default Resources;