import Home from "./components/Home";
import Login from "./RoutingFiles/Login";
import Header from "./components/header";
import Alwayson from "./RoutingFiles/Alwayson";
import Geolift from "./RoutingFiles/Geolift";
import About from "./RoutingFiles/About";
import Resources from "./RoutingFiles/Resources";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import ContactUs from "./components/ContactUs";
import { NavLink, Route, Routes } from 'react-router-dom';



function App() {


  return (
    <>

      <Header/>

      <Routes>
        <Route path="/"                      element={<Home/>}     />     
        <Route path="/login"                 element={<Login/>}    />
        <Route path="catalog/always-on-mmm"  element={<Alwayson/>} />
        <Route path="catalog/geo-lift"       element={<Geolift/>}  />
        <Route path="/about"                 element={<About/>}    />
        <Route path="/resources"             element={<Resources/>}    />

        <Route path="/privacy-policy"             element={<PrivacyPolicy/>}    />
        <Route path="/terms-of-service"           element={<TermsOfService/>}    />

        <Route path="/contact-us"           element={<ContactUs/>}    />
      </Routes>
    
    </>
  );
}

export default App;
