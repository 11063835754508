import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";    
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { HiOutlineLockClosed } from "react-icons/hi2";
import './Login-Signup.css';


function Login() {      
  
  
      // LOGIN
      const [formData, setFormData] = useState(  {email: "", password: "", chk: false}  );
      const [showPassword, setShowPassword] = useState(false);
      const navigate = useNavigate();
  
  
      function updateRealtime(event) {
        setFormData(prevFormData => {                 // Retaining unchanged values as it is  &  Updating changed value 
          return {  ...prevFormData,   [event.target.name]: event.target.type==="checkbox"  ?  event.target.checked   :   event.target.value   } // "...prevFormData" means that copy values of input other fields as it is  +  Update value in current input field where user is currently typing
        });                                                                                                                                      // "[event.target.name]" represents : "event.target.firstName", "event.target.lastName", "event.target.email" etcccc
      }        
      
      
      function submitHandler(event) {
          event.preventDefault();
  
          const obj = {             
              loginEmail: formData.email,
              loginPassword: formData.password,
              loginRememberMe: formData.chk
          };                          
            
          console.log(obj);           
        
          // formData.email = "";         If I want to empty input boxes after submit button
          // formData.password = "";
          // formData.chk = false;
  
        //  dispatch(login(formData.email, formData.password, navigate));       //  STEP 13-8
      }


  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
      <div style={{width:"1540px", height:"638px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D3D3D3"}}>

            
        <div className='login-page-left-section'>
           


          <form onSubmit={submitHandler} className="form-login  flex flex-col w-full">            

              <label className='w-full relative'>              
                  <p className='label-font    text-[1.05rem]  mb-1 leading-[1.375rem]'>  Username <sup className='star'>*</sup>  </p> 
                  <FaUser   fontSize="1.4rem"  className='emailIcon'/>
                  <input    type="email"    onChange={updateRealtime}    name="email"    value = {formData.email}      required      placeholder="Enter your Username"      className='pl-14  rounded-[0.5rem]  w-full p-[12px]  input-border'/>
              </label>                                  {/* To connect label with input : We have 2 ways(one is this one, another is which is done in "9-React-Forms")  */}


              <label className='w-full relative mt-4'>
                  <p className='label-font    text-[1.05rem] mb-1 leading-[1.375rem]'>  Password<sup className='star'>*</sup>  </p>
                  <HiOutlineLockClosed    fontSize="1.72rem"  className='passwordIcon'/>
                  <input    type= {showPassword ? ("text") : ("password")}    onChange={updateRealtime}    name="password"    value = {formData.password}      required       placeholder="Enter your Password"      className='pl-14  rounded-[0.5rem] w-full p-[12px] input-border'/>     {/*  if "showPassword" is true, then show typed password as text, if "showPassword" is false , then show typed password as password(dots)  */}
                                  

                  <span className='absolute right-4 top-[38px] cursor-pointer' onClick={() => setShowPassword((prev) => !prev)}>                  {/*  When user clicks on icon, change value of "showPassword"  */}
                      {showPassword ? (<AiOutlineEyeInvisible fontSize={24} fill='#AFB2BF'/>) : (<AiOutlineEye fontSize={24} fill='#AFB2BF'/>)}   {/*  Show icon based on value of "showPassword"  */}
                  </span>


                  <div className='flex  justify-between  mt-2'>
                      <div className='flex  align-center'>
                          <input    type="checkbox"  onChange={updateRealtime}    checked={formData.chk}     name="chk"   id="zx1"/>  {/*  for type="checkbox", we use "checked={}" instead of "value={}"  */}
                          <label    htmlFor='zx1'  className='RememberMe'>Remember me</label>                                               {/*  id="zx1" is linked with label, so that even if I click on label the checkbox gets checked  */}
                      </div>

                  </div>

              </label>


              <button className='custom-btn   rounded-[8px] px-[12px] py-[10px]'>  Login  </button>

          </form>
                


        </div>


      </div>
    </div>
  )
}

export default Login;