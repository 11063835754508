import { Link } from 'react-router-dom';


function Footer() {          
    

    return (
        <div   style={{display:"flex", justifyContent:"center"}}> 
            <div style={{backgroundColor:"rgb(25,38,77)", width:"1536px", height:"80px", display:"flex", justifyContent:"center", alignItems:"center", gap:"2rem"}}>
                
                <Link to="/privacy-policy"> 
                    <div style={{color:"white", textDecoration: "underline", textDecorationThickness: "1px" }}>Privacy Policy</div>
                </Link>

                <Link to="/terms-of-service"> 
                    <div style={{color:"white", textDecoration: "underline", textDecorationThickness: "1px" }}>Terms of Service</div>
                </Link>

            </div>
        </div>
    )
}
  
export default Footer;