import React, { useEffect, useRef } from 'react';
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Footer from "./Footer";
import useWindowDimensions from '../useWindowDimensions';          // Using it for responsiveness in terms of height



function Home() {                


  const sec2Ref = useRef(null);
  const sec3Ref = useRef(null);

  const scrollToRef = (ref) => {
      if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
      }
  };




  const scrollToSecondSection = () => {
      scrollToRef(sec2Ref)
  };
  const scrollToThirdSection = () => {
      scrollToRef(sec3Ref)
  };


  useEffect(() => {
      const scrollToSecondTimeout = setTimeout(() => {
          scrollToSecondSection();
      }, 12000);                                         // 12 seconds in milliseconds

      const scrollToThirdTimeout = setTimeout(() => {
          scrollToThirdSection();
      }, 24000);                                         // 24 seconds in milliseconds

      
      return () => {                                     // Clean up timeouts to avoid memory leaks
          clearTimeout(scrollToSecondTimeout);
          clearTimeout(scrollToThirdTimeout);
      };
  }, []);





  const { width, height } = useWindowDimensions();
  let Xheight = 88.01;
  if(width >= 1200  &&  width <= 1300) Xheight=78.01
  else if(width >= 900  &&  width <= 1100) Xheight=68.01
  else if(width >= 335  &&  width <= 900) Xheight=65.01



  return (
    <div  style={{height:height-Xheight, overflow:"auto"}}>
        <Section1/>
        <Section2    sec2Ref={sec2Ref}/>
        <Section3    sec3Ref={sec3Ref}/>
        <Footer/>
    </div>
  )
}

export default Home;